// Variable overrides
:root {
  --bakgroundColor: #f0f0f0;
  --primaryColor: #ec7211 !important;
  --primary: #ec7211 !important;
}

.orange {
  color: #ec7211;
}

.bg-orange {
  background-color: rgba(251, 88, 80, 1);
}

*:disabled {
  cursor: not-allowed;
}

.bg-highlight {
  background: #f5f5f5;
}

/************ INPUT STYLES ****************/
.form-control-alternative {
  box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%) !important;
  transition: box-shadow 0.15s ease !important;
}

// textarea.form-control {
//     height: auto !important;
// }

input.form-control:focus {
  border-color: #ec7211 !important;
  box-shadow: 0 0 0 0.2rem rgba(236, 114, 17, 0.25) !important;
}

.form-control-label {
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

.btn-orange {
  background: #fb5850;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  padding: 10px 30px;
}

.btn-orange:focus {
  background: #fb5850 !important;
  color: #fff !important;
}

.btn-orange:hover {
  background: #fb5850 !important;
  color: #000 !important;
}

.btn-orange:disabled {
  background: #fb5950c9;
}

/************ PASSWORD MASK STYLES ****************/
.password-show {
  background: none !important;
  font-size: 22px;
  top: 50% !important;
  transform: translateY(-50%);
  margin-top: 0 !important;
  color: #5495f9 !important;
}

/************ HORIZONTAL RULES STYLES ****************/
hr {
  color: #dddddd;
}

/************ MAKE THE CUSOR POINTER ****************/
.pointer {
  cursor: pointer;
}


.dropdown-options-list {
  background: white;
  border-radius: 8px; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  overflow-y: auto;
  width: 100%;
  z-index: 10;
  padding: 8px 0;
  border: none;
  margin-top: 5px;
  height: 100%;
  max-height: 130px;
}


.dropdown-options-list li {
  padding: 12px 16px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  list-style: none;
}

.dropdown-options-list li:hover {
  background-color: #f7f7f7;
}
