/***********Login Screen*********/
.bg-login {
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: rgba(87, 33, 72, 1);
}

/***********Links*********/
.link,
.c-header-nav-link {
  cursor: pointer;
  color: #5e72e4 !important;
}

.link:hover,
.c-header-nav-link:hover {
  color: #233dd2 !important;
}

/***********Side Bar*********/
.c-sidebar-fixed .c-sidebar-nav-dropdown-items .c-sidebar-nav-item {
  padding-left: 15px;
}

.c-sidebar-minimized .c-sidebar-nav-dropdown-items .c-sidebar-nav-item {
  padding-left: 0px;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle::after {
  transform: rotate(-90deg);
}

.c-sidebar .c-sidebar-nav-dropdown-toggle {
  background: rgba(87, 33, 72, 1) !important;
}

.c-sidebar .c-sidebar-nav-dropdown-toggle:hover {
  background: rgba(251, 88, 80, 1) !important ;
}

html:not([dir="rtl"])
  .c-sidebar-nav-dropdown.c-show
  > .c-sidebar-nav-dropdown-toggle::after {
  transform: rotate(90deg);
}

.c-sidebar-nav-dropdown-toggle:hover,
.c-sidebar-nav-item:hover,
.c-sidebar-minimized .c-sidebar-nav-link:hover {
  background: rgba(251, 88, 80, 1) !important;
}

// .c-sidebar-nav-dropdown-toggle:hover, .c-sidebar-nav-item:hover, .c-sidebar-minimized .c-sidebar-nav-link {
//     background: rgb(129, 59, 108) !important;
// }

.c-sidebar-fixed:not(.c-sidebar-minimized) .c-sidebar-nav-link:hover {
  background: transparent !important;
}

.c-sidebar-fixed:not(.c-sidebar-minimized)
  .c-sidebar-nav-dropdown-toggle:hover {
  background: rgba(251, 88, 80, 1) !important;
}

.c-sidebar.c-sidebar-minimized
  .c-sidebar-nav
  > .c-sidebar-nav-dropdown
  > .c-sidebar-nav-dropdown-items {
  background: #461a3a;
}

// .c-sidebar-nav-dropdown:hover {
//   background: #303c54 !important;
// }

.c-sidebar-brand,
.c-sidebar {
  background: rgba(87, 33, 72, 1) !important;
}

/***********Global FOnt Size*********/
.f-18 {
  font-size: 18px;
}

/***********Loader*********/
.ktHroX {
  position: absolute !important;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: none;
}

/***********MISC*********/
.pointer {
  cursor: pointer;
}

.component-content {
  padding: 30px;
}

.route-container-no-bg {
  padding: 30px;
}

.route-container {
  padding: 3%;
  background-color: #f7fafc !important;
}

.error-span {
  color: red !important;
}

.success-span {
  color: green !important;
}

.card {
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  width: 100%;
  border-radius: 10px;
}

.card-header {
  background-color: #1184ef !important;
  color: #fff;
  padding: 20px;
}

.card-body {
  font-size: 14px;
}

.card-actions {
  font-size: 16px;
  padding: 1.5rem;
}

.modal-header .close {
  display: none;
}

.v-center {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.btn-warning {
  text-decoration: none !important;
}

/***********Metrics*********/
.chart {
  padding: 2rem 2rem 10px;
  background-color: white;
  border-radius: 5.9px;
  height: 100%;
}

.chart:hover {
  cursor: pointer;
  transform: scale(1.02);
}

@media (max-width: 991px) {
  .chart {
    height: 400px;
  }
}

@media (max-width: 600px) {
  .chart {
    height: 300px;
  }
}

.chart-req {
  height: 1000px;
}

.chart-req canvas {
  height: 800px !important;
}

@media (min-width: 1200px) {
  .chart {
    padding: 1.5rem 2rem;
  }

  .chart-long {
    height: 400px;
  }

  .chart-long canvas {
    height: 280px !important;
  }
}

.chart h5 {
  color: black;
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 20px;
  text-align: center;
}

.req-table {
  margin-left: 1.2rem;
  margin-right: 1.2rem;
}

.__json-pretty__ {
  margin-bottom: 0;
  line-height: 1.5;
}

/***********New Metrics*********/
.progress-xs {
  height: 4px;
}

.pie-hover {
  background-color: rgba(35, 47, 62, 0.8);
  color: #fff;
  font-weight: bold;
  border-radius: 5px;
}

/***********Badges*********/
/*.badge-primary {
  color: #2643e9;
  background-color: rgba(203, 210, 246, 0.5); }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #324cdd; }

.badge-secondary {
  color: #cfe3f1;
  background-color: rgba(255, 255, 255, 0.5); }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #d2e3ee; }

.badge-success {
  color: #1aae6f;
  background-color: rgba(147, 231, 195, 0.5); }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #24a46d; }

.badge-info {
  	background-color: rgba(136, 230, 247, 1);
}

.badge-warning {
  	background-color: rgba(254, 201, 189, 1);
}

.badge-danger {
  	background-color: rgba(251, 175, 190, 1);
}

.badge-light {
	background-color: rgba(244, 245, 246, 1);
}

.badge-dark {
	background-color: rgba(90, 101, 112, 1);
}

.badge-default {
  	background-color: rgba(52, 98, 175, 1);
}

.badge-white {
  	background-color: rgba(255, 255, 255, 1);
}

.badge-neutral {
  	background-color: rgba(255, 255, 255, 1);
}

.badge-darker {
  	background-color: rgba(64, 64, 64, 1);
}*/

/************Table Styles********/
.table td,
.table th {
  font-size: 0.8125rem;
  white-space: break-spaces;
}

table {
  margin-top: 20px;
}

thead {
  background: #2b394a !important;
  color: #fff !important;
}

table thead tr th {
  background: #2b394a !important;
  color: #fff !important;
  border-top: none !important;
}

table tr th,
table tr td {
  text-align: center;
}

table thead tr th:first-child {
  border-top-left-radius: 15px;
}

table thead tr th:last-child {
  border-top-right-radius: 15px;
}

table tbody tr td {
  vertical-align: middle !important;
}

table .form-group {
  margin-bottom: 0 !important;
}

/************Modal Styles********/
.modal-header {
  justify-content: left;
  border-bottom: none;
}

.modal-footer {
  border-top: none;
}

/************Tags Input********/
div.form-control,
.react-tagsinput {
  height: 100% !important;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02) !important;
}

.react-tagsinput--focused {
  border-color: rgba(251, 88, 80, 1) !important;
  box-shadow: 0 0 0 0.2rem rgba(236, 114, 17, 0.25) !important;
}

.react-tagsinput-tag {
  background: #2f2f2f !important;
  color: #fff !important;
  font-weight: bold !important;
}

/************NAV BAR/TABS STYLES****************/
.nav-link,
.nav-link:hover {
  font-weight: bold;
  color: #000;
  font-size: 14px;
  border: none !important;
}

.nav-item {
  position: relative;
}

.nav-tabs .active {
  color: rgba(251, 88, 80, 1) !important;
  background-color: transparent !important;
}

.nav-tabs .active:after {
  content: "";
  background-color: rgb(236, 114, 17);
  color: rgb(236, 114, 17);
  border-radius: 5px;
  display: block;
  bottom: -1.5px;
  left: 0px;
  position: absolute;
  width: 100%;
  border: 3px solid;
}

/************Pagination STYLES****************/
.pagination li a {
  border: 1px solid #dee2e6;
  padding: 10px 20px;
  color: rgba(251, 88, 80, 1);
  font-weight: bold;
}

.pagination li.disabled a {
  background-color: #ccc;
  color: #666;
}

.pagination li.active a {
  color: #fff;
  background-color: rgba(251, 88, 80, 1) !important;
}

.pagination li:first-child a {
  border-radius: 5px 0 0 5px;
}

.pagination li:last-child a {
  border-radius: 0 5px 5px 0;
}

.pagination li a:hover {
  text-decoration: none;
  background: #f5f5f5;
}

.pagination li.disabled a:hover {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination li a:focus {
  border-color: rgba(251, 88, 80, 1) !important;
  box-shadow: 0 0 0 0.2rem rgba(236, 114, 17, 0.25) !important;
  outline: none;
}
