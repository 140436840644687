// Here you can add other styles
/************ FONT IMPORT ****************/
@font-face {
    font-family: "Amazon Ember";
    src: url("//db.onlinewebfonts.com/t/157c6cc36dd65b1b2adc9e7f3329c761.eot");
    src: url("//db.onlinewebfonts.com/t/157c6cc36dd65b1b2adc9e7f3329c761.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/157c6cc36dd65b1b2adc9e7f3329c761.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/157c6cc36dd65b1b2adc9e7f3329c761.woff") format("woff"), url("//db.onlinewebfonts.com/t/157c6cc36dd65b1b2adc9e7f3329c761.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/157c6cc36dd65b1b2adc9e7f3329c761.svg#Amazon Ember") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: "Amazon Ember";
    src: url("//db.onlinewebfonts.com/t/02fcddff61b677f4989d2933d574ca89.eot");
    src: url("//db.onlinewebfonts.com/t/02fcddff61b677f4989d2933d574ca89.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/02fcddff61b677f4989d2933d574ca89.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/02fcddff61b677f4989d2933d574ca89.woff") format("woff"), url("//db.onlinewebfonts.com/t/02fcddff61b677f4989d2933d574ca89.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/02fcddff61b677f4989d2933d574ca89.svg#Amazon Ember") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/************ DOCUMENT STYLES ****************/
html {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "Amazon Ember", sans-serif !important;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    letter-spacing: 1px;
    overflow-x: hidden;
}

body {
    height: 100%;
    font-family: "Amazon Ember", sans-serif !important;
    -webkit-font-smoothing: antialiased;
}

#root {
    height: 100%;
}

button:focus,
select:focus,
textarea:focus {
    border-color: #ec7211 !important;
    box-shadow: 0 0 0 0.2rem rgba(236, 114, 17, 0.25) !important;
}

/************ TOASTER STYLES ****************/
.Toastify__toast-body {
    text-align: left;
}

.Toastify__toast--error {
    background: #e8416b !important;
}

.Toastify__toast--success {
    background: #029d8b !important;
}

/************ VERTICAL CENTERING CLASSES ****************/
.relative-center {
    top: 50%;
    position: relative;
    transform: translateY(-50%);
}

.vertical-center {
    top: 50%;
    transform: translateY(-50%);
}

/************ MODAL WINDOW STYLES ****************/
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;
    backdrop-filter: blur(5px);
}

/************ FLAG DROPDOWN ****************/
.selected-flag:focus{
    outline: none;
    border: none;
}

.intl-tel-input .flag-container .arrow {
    color: black !important;
}
